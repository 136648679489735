.tabs {
    width: 100%;
    margin: 0 auto;
    .tabNav {
        display: flex;
        flex-direction: row;
        list-style: none;
        li {
            flex: 1;
            background: $tab;
            color: $inverse;
            text-align: center;
            text-transform: uppercase;
            font-family: $bold;
            font-size: 1.9rem;
            line-height: 1;
            padding: 1.35rem 1rem 1.15rem;
            cursor: pointer;

            @media #{$tablet} {
                font-size: 2.2rem; }

            &.active {
                background: $inverse;
                color: $primary; } } }

    .tabContainer {
        .tab {
            display: none;

            &.active {
                display: block; }

            nav {
                background: $inverse;
                color: $primary;
                padding: 2.4rem 1.5rem;

                &:after {
                    content: '';
                    bottom: -3rem;
                    left: 50%;
                    border: solid transparent;
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                    border-top-color: $inverse;
                    border-width: 1.5rem;
                    transform: translateX(-50%);
                    z-index: 1; }

                &.special {
                    li {
                        pointer-events: none; }
                    &:after {
                        content: none; } }

                ul {
                    margin: -.75rem 0;
                    li {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        padding: .75rem 0;
                        line-height: 1;
                        cursor: pointer;
                        span {
                            width: 1.8rem;
                            height: 1.8rem;
                            background: $inverse;
                            border: .15rem solid $primary;
                            border-radius: 50%;
                            display: block;
                            @include transition(background);
                            margin-right: 1rem;

                            &.bullet {
                                background: url(/assets/image/layout/bullet.svg) center center no-repeat;
                                height: 1rem;
                                border: none;
                                border-radius: 0; } }
                        &.active {
                            span {
                                background: $primary; } } } } }
            .content {
                background: $quartery;
                display: none;
                padding: 3rem 1.5rem;
                text-align: center;

                &:after {
                    content: '';
                    bottom: -1.5rem;
                    right: -1.5rem;
                    border: solid transparent;
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                    border-top-color: $primary;
                    border-width: 1.5rem;
                    transform: rotate(-45deg); }


                &.active {
                    display: block; } } } } }
