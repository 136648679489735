#contact {
    width: 100%;
    background-image: none;

    @media #{$tablet} {
        background: url('/assets/image/content/contact/portraits_kontakt_hg.jpg') center center no-repeat;
        background-size: cover;
        padding-bottom: 0;

        .row {
            margin-bottom: 0;

            .col {
                margin-bottom: 0;

                &:first-of-type {
                    justify-content: center;
                    display: flex;
                    flex-direction: column; } } } }

    .contactPossibilities {
        margin-top: 5rem;

        @media #{$tablet} {
            margin: 0; }

        a {
            font-size: 2.4rem;
            line-height: 112.5%;
            display: block;

            @media #{$tablet} {
                font-size: 3.2rem; } } }

    h5 {
        position: absolute;
        bottom: 1rem;
        left: 0;
        z-index: 2;

        @media #{$desktop} {
            bottom: 8rem;
            left: -4rem; } } }
