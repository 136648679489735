.transitionScreen {
    width: 100%;
    width: 100vw;
    height: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;
    opacity: 0;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    &.animateIn {
        z-index: 999;
        opacity: 1; }

    &.animateOut {
        z-index: 999;
        opacity: 1; } }
