.button {
    background: $primary;
    color: $inverse;
    font-size: 2.2rem;
    line-height: 1;
    padding: 1.3rem 3rem 1.1rem;
    text-align: center;
    color: $inverse;
    border: 2px solid transparent;
    @include transition(color background border-color);
    display: inline-block;
    margin-bottom: 4rem;
    text-transform: uppercase;

    &.noMb {
        margin-bottom: 0; }

    &.inverse {
        background: $inverse;
        color: $primary; }

    &:hover {
        background: $inverse;
        color: $primary;
        border-color: $primary;

        &.inverse {
            background: $primary;
            color: $inverse;
            border-color: $inverse; } }

    &.disabled {
        background: $lightestGrey;
        border: none;
        position: relative;
        z-index: 400;

        span {
            position: absolute;
            top: 70px;
            left: 0;
            padding: 20px;
            max-width: none;
            width: 100%;
            background: $inverse;
            font-size: 16px;
            border: 2px solid $red;
            font-family: $light;
            text-transform: none;
            color: $red;
            text-align: left;
            line-height: 125%;

            &:before,
            &:after {
                bottom: 100%;
                left: 50%;
                border: solid transparent;
                content: "";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none; }

            &:after {
                border-color: rgba(255, 0, 0, 0);
                border-bottom-color: #ffffff;
                border-width: 10px;
                margin-left: -10px; }

            &:before {
                border-color: rgba(255, 0, 0, 0);
                border-bottom-color: $red;
                border-width: 13px;
                margin-left: -13px; } }

        @media #{$desktop} {
            span {
                top: 50%;
                left: auto;
                right: -290px;
                max-width: 260px;
                width: auto;
                background: $inverse;
                transform: translateY(-50%);

                &:before,
                &:after {
                    right: 100%;
                    top: 50%;
                    left: auto;
                    bottom: auto;
                    border: solid transparent;
                    content: "";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none; }

                &:after {
                    border-color: rgba(255, 0, 0, 0);
                    border-right-color: #ffffff;
                    border-bottom-color: transparent;
                    border-width: 10px;
                    margin-top: -10px;
                    margin-left: 0; }

                &:before {
                    border-color: rgba(255, 0, 0, 0);
                    border-bottom-color: transparent;
                    border-right-color: $red;
                    border-width: 13px;
                    margin-top: -13px;
                    margin-left: 0; } } }

        &:hover {
            color: inherit;
            background: $lightestGrey; } } }

button, a {
    border: none;
    background: transparent;


    &.toggleScroll {
        cursor: pointer;
        color: $primary;
        text-transform: uppercase;
        font-family: $bold;
        font-size: 2.2rem;
        .arrow {
            display: block;
            width: 7.3rem;
            height: 4.6rem;
            background: url('/assets/image/layout/icons/arrowDown.svg') center center no-repeat;
            background-size: 3.7rem 1.8rem;
            margin: -.75rem auto 0; } } }


@keyframes bounce {
    from,
    20%,
    53%,
    80%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translate3d(-50%, 0, 0); }

    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(-50%, -2rem, 0); }

    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(-50%, -1rem, 0); }

    90% {
        transform: translate3d(-50%, -.4rem, 0); } }

@keyframes bounceMobile {
    from,
    20%,
    53%,
    80%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translate3d(0, 0, 0); }

    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -2rem, 0); }

    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -.7rem, 0); }

    90% {
        transform: translate3d(0, -.4rem, 0); } }
