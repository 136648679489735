#loadingAnimation {
    width: 100%;
    height: 100%;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    &.fadeOut {
        .inner {
            opacity: 0; } }
    &.hide {
        opacity: 0;
        visibility: hidden;
        z-index: -1; }

    .transition {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-position: center center;
        background-repeat: no-repeat;
        background-color: $primary;
        background-size: cover; }

    .inner {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 1;
        background: url('/assets/image/layout/x.svg') center center no-repeat;
        background-size: auto 100%;
        background-color: $primary;
        z-index: 9999;

        @supports not (-ms-high-contrast: none) {
            @media #{$desktop} {
                @include transition(opacity); } }

        .slogan {
            span {
                @include fluid-type(360px, 1920px, 55px, 140px);
                display: block;
                line-height: 1;
                font-family: $bold;
                text-transform: uppercase; } } } }

