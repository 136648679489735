#firstscreen {
    position: relative;
    &.ausschreibung {
        position: relative;
        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.5);
            z-index: 1;
            display: none; } } }


#ausschreibung {
    .headline,
    .small-headline {
        font-weight: 600;
        text-transform: uppercase;
        line-height: 130%; } }



#ausschreibung {
    display: none;
    position: absolute;
    width: 100%;
    max-width: 1020px;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    padding: 2.5rem 2.5rem 1rem 2.5rem;
    z-index: 500;
    .header {
        background-color: #002981;
        padding: 30px 20px 20px 20px;

        @media #{$tablet} {
            padding: 35px; }

        @media #{$desktop} {
            padding: 45px; }

        .close {
            cursor: pointer;
            position: absolute;
            top: 5px;
            right: 5px;
            content: "";
            width: 25px;
            height: 25px;
            background: url(/assets/image/layout/close.svg) bottom center no-repeat;
            color: #fff;
            font-weight: 300;
            font-family: Arial, sans-serif;

            @media #{$tablet} {
                top: 10px;
                right: 10px;
                width: 30px;
                height: 30px; }

            @media #{$desktop} {
                top: 20px;
                right: 20px; } }


        .headline {
            font-size: 25px;
            color: #fff;
            hyphens: auto;

            @media #{$tablet} {
                font-size: 35px; }

            @media #{$desktop} {
                font-size: 45px; } } }




    .content {
        background-color: #0544A1;
        .row {
            max-width: 100%;
            width: 100%;
            margin: 0;
            .col {

                &:first-of-type {
                    padding: 20px;

                    @media #{$tablet} {
                        padding: 35px; }

                    @media #{$desktop} {
                        padding: 45px; } }


                .small-headline {
                    font-size: 22px;
                    color: #FABB00;
                    margin-bottom: 15px;
                    display: block;

                    @media #{$tablet} {
                        font-size: 25px; }

                    @media #{$desktop} {
                        font-size: 32px; } }



                p {
                    color: #fff;
                    font-size: 16px;

                    @media #{$desktop} {
                        font-size: 19px; } }


                img {
                    position: absolute;
                    right: -10px;
                    bottom: 0;
                    display: none;

                    @media #{$tablet} {
                        display: block; } } } } } }








