.imageHolder {
    width: 100%;
    width: 100vw;
    max-width: 144rem;
    height: auto;
    margin: 0 -2.4rem 6rem;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    @media #{$tablet} {
        margin: 0 -3rem 8rem;
        background-size: contain; }

    @media #{$desktop} {
        margin: 0 0 8rem; }

    &:after {
        content: '';
        width: 100%;
        padding-bottom: 75%;
        display: block; } }
