nav {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    .navToggle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.5rem;
        height: 2rem;
        margin: -.25rem;
        cursor: pointer;

        @media #{$desktop} {
            display: none; }

        &:before,
        &:after {
            content: '';
            display: block;
            width: calc(100% - .5rem);
            height: .2rem;
            background: $inverse;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            @include transition(transform, .3s);
            will-change: transform; }

        &:before {
            top: 0.35rem; }

        &:after {
            bottom: 0.25rem; }

        span {
            display: block;
            width: calc(100% - .5rem);
            height: .2rem;
            background: $inverse;
            opacity: 1;
            @include transition(opacity, .3s);
            pointer-events: none; }

        &.open {

            &:before {
                top: 50%;
                transform: translate(-50%, -50%) rotate(45deg); }

            &:after {
                top: 50%;
                bottom: unset;
                transform: translate(-50%, -50%) rotate(-45deg); }

            span {
                opacity: 0; } } }

    ul {
        list-style: none;

        a {
            display: block; }

        &.main {
            opacity: 0;
            pointer-events: none;
            transform: translateX(-100%);
            will-change: transform;
            transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1),padding 0.3s cubic-bezier(0.4, 0, 0.2, 1);
            background: $primary;
            margin: -1.25rem 0;
            position: fixed;
            top: 9.2rem;
            left: 0;
            width: 100%;
            width: 100vw;
            max-width: 144rem;
            padding: 4rem 0;

            @media #{$tablet} {
                width: 50%;
                width: 50vw;
                height: 100%;
                height: 100vh; }

            @media #{$desktop} {
                opacity: 1;
                pointer-events: auto;
                transform: none;
                position: relative;
                top: unset;
                left:  unset;
                background: transparent;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                padding: 3.8rem 0 0;
                margin: 0;
                width: auto;
                height: unset; }

            li {
                display: block;
                transform: translateX(-100%);
                @include transition(transform, .2s);
                padding: 1.25rem 0;

                @media #{$desktop} {
                    transform: none;
                    padding: 0 1.5rem; }

                a {
                    display: block;
                    color: $secondary;
                    font-size: 2.5rem;
                    line-height: 1;
                    letter-spacing: 0.05em;
                    text-transform: uppercase;
                    text-align: center;
                    @include transition(color);

                    @media #{$tablet} {
                        padding-left: 3rem;
                        text-align: left; }

                    @media #{$desktop} {
                        font-size: 1.8rem;
                        font-family: $bold; }


                    &:hover {
                        color: $inverse; } } }

            &.open {
                opacity: 1;
                pointer-events: auto;
                transform: translateX(0);

                li {
                    transform: translateX(0);
                    animation-name: moveIn;
                    animation-fill-mode: both;
                    animation-duration: .3s;

                    &:first-child {
                        animation-delay: .1s; }

                    &:nth-child(2) {
                        animation-delay: .2s; }

                    &:nth-child(3) {
                        animation-delay: .3s; }

                    &:nth-child(4) {
                        animation-delay: .4s; }

                    &:last-child {
                        animation-delay: .5s; } } } }

        &.meta {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            margin-left: 1rem;

            @media #{$desktop} {
                display: none; }

            li {
                a {
                    color: $inverse;
                    font-size: 1.5rem;
                    padding: 0 1rem; } } } } }

.scroll {
    nav {
        ul {
            &.main {
                top: 5.2rem;
                @media #{$desktop} {
                    top: unset;
                    padding: 0.8rem 0 0; } } } } }
