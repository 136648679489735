$primary: #00469B;
$secondary: #FABB00;
$tertiary: #4D4D4D;
$quartery: #002C7C;
$quintary: #919191;

$tab: #85959D;

$black: #000;
$inverse: #fff;
$grey: #4D4D4D;
$lightestGrey: #C6C6C6;
$lighterGrey: #6E6E6E;
$lightGrey: #ECECEC;
$red: #bf350b;

$light: TradeGothicLTPro-Light, Arial, sans-serif;
$bold: TradeGothicLTPro-Bold, Arial, sans-serif;
$bold2: TradeGothicLTPro-Bd2, Arial, sans-serif;

$tablet: "only screen and (min-width: 768px)";
$desktop: "only screen and (min-width: 1024px)";
$desktopMid: "only screen and (min-width: 1440px)";
$desktopBig: "only screen and (min-width: 1921px)";

$gap: 4;
