@import '../../../node_modules/tiny-slider/dist/tiny-slider.css';
@import '../../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min.css';

@import './global/typo.css';
@import './global/var';
@import './global/mixins';
@import './global/base';
@import './global/grid';

@import './global/transitionScreen';
@import './global/button';
@import './global/header';
@import './global/nav';
@import './global/langToggle';
@import './global/bg';
@import './global/tabContainer';
@import './global/slider';
@import './global/imageHolder';
@import './global/video';
@import './global/footer';
@import './global/loadingAnimataion';

@import './content/firstscreen';
@import './content/sixtySeconds';
@import './content/partnership';
@import './content/register';
@import './content/contact';
@import './content/legal';
@import './content/covid19';
@import './content/_ausschreibung';
