footer {
    background: $inverse;
    margin-top: .1rem;
    @media all and (-ms-high-contrast:none) {
        width: 100%;
        overflow: hidden; }

    ul {
        list-style: none; }

    .logoFull {
        height: 3rem;
        max-width: 13.7rem;

        use {
            fill: $inverse; } }

    .transition {
        padding: 2.4rem 2.4rem 8rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;
        height: 75%;
        height: 75vh;
        flex-direction: column;
        @media all and (-ms-high-contrast:none) {
            width: 100vw; }

        .up {
            cursor: pointer;
            width: 7.3rem;
            height: 4.6rem;
            background: url('/assets/image/layout/icons/arrowDown.svg') center center no-repeat;
            background-size: 3.7rem 1.8rem;
            opacity: 1;
            z-index: 45;
            position: absolute;
            top: 6rem;
            left: 50%;
            transform-origin: 0%;
            transform: rotate(180deg) translateX(-50%); }

        .button {
            margin-bottom: 0;
            opacity: 1;

            @media #{$desktopMid} {
                margin-bottom: 3rem; } } }

    .slideShow {
        background: $lightGrey;
        padding: 6rem 0 8rem;

        @media #{$tablet} {
            padding: 8rem; }

        h3 {
            color: $primary; }

        .sliderControls {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, 0%);
            width: calc(100% - 3rem);
            height: 10rem;
            appearance: none;
            -webkit-appearance: none;
            outline: none;

            &:focus {
                outline: none;
                appearance: none;
                -webkit-appearance: none; }
            span {
                display: block;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                transform-origin: center center;
                width: 5rem;
                height: 6.5rem;
                background: url('/assets/image/layout/sliderArrowBlue.svg') center center no-repeat;
                background-size: contain;
                font-size: 0;
                @include transition(transform, .5s);
                cursor: pointer;
                appearance: none;
                -webkit-appearance: none;
                outline: none;

                &:focus {
                    outline: none;
                    appearance: none;
                    -webkit-appearance: none; }

                &.prev {
                    left: 0;
                    transform: rotate(-180deg) translateY(-50%);
                    transform-origin: top; }

                &.next {
                    right: 0; } } }

        .tns-outer {
            margin-left: auto;
            margin-right: auto;

            .memberslide {
                li {
                    picture {
                        img {
                            display: block; } }

                    .name {
                        background: $primary;
                        color: $inverse;
                        padding: 2rem;
                        font-size: 1.9rem;
                        line-height: 131%;

                        &:after {
                            content: '';
                            bottom: -1.5rem;
                            right: -1.5rem;
                            border: solid transparent;
                            height: 0;
                            width: 0;
                            position: absolute;
                            pointer-events: none;
                            border-top-color: $lightGrey;
                            border-width: 1.5rem;
                            transform: rotate(-45deg); } } } } } }


    .footer {
        background: $primary;
        padding: 4rem 1.4rem;
        color: $inverse;
        font-size: 1.4rem;
        line-height: 128%;
        width: 100%;

        &:after {
            content: '';
            width: 100%;
            width: 100vw;
            background: $primary;
            height: 100%;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: -1; }

        @media #{$tablet} {
            padding: 4rem 3rem; }

        @media #{$desktop} {
            padding: 4rem 0; }

        a {
            color: $inverse;
            display: block;

            &:hover {
                text-decoration: underline; } }

        .bold {
            font-family: $bold2; }

        @media all and (-ms-high-contrast:none) {
            .row {
                width: 100%; } } }
    nav {
        background: $lighterGrey;
        dispaly: flex;
        align-items: center;
        justify-content: center;
        padding: 2rem 0 1.9rem;

        @media all and (-ms-high-contrast:none) {
            width: 100%; }

        ul {
            display: flex;
            justify-content: center;
            align-items: center;

            li {
                a {
                    color: $inverse;
                    line-height: 1;
                    font-size: 1.2rem;
                    padding: 0 .75rem;

                    &:hover {
                        text-decoration: underline; } } } } }

    .animationWrapper {
        will-change: transform;
        @media all and (-ms-high-contrast:none) {}
        background: $primary; }
    .logo {
        position: absolute !important;
        top: 0;
        left: 0;
        transform: none;

        @media all and (-ms-high-contrast:none) {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%); } } }
