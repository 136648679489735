* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    position: relative; }

html {
    font-size: 10px;
    position: relative;
    @media all and (-ms-high-contrast:none) {
        width: 100%; } }

body {
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.9rem;
    line-height: 133.333%;
    color: $inverse;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    scroll-behavior: smooth;
    background: $primary;
    @media all and (-ms-high-contrast:none) {
        width: 100%; }

    // Typo
    font-family: $light;
    font-weight: normal;
    font-style: normal;

    .marquee span, .button {
        font-family: $bold; }

    strong {
        font-family: $bold2;
        color: $secondary;
        font-weight: 400; }

    main {
        width: 100%;
        overflow-x: hidden; }

    section {
        background: $primary;
        padding: 6rem 2.4rem;

        @media #{$tablet} {
            padding: 13rem 3rem; }

        article {
            padding: 6rem 2.4rem;

            @media #{$tablet} {
                padding: 13rem 3rem; }

            @media #{$desktop} {
                padding: 13rem 0; }

            &.min {
                min-height: 44.5rem;

                @media #{$tablet} {
                    min-height: 75vh; } }


            &:last-child {
                margin-bottom: 0; } } }

    a {
        text-decoration: none;
        color: $secondary;
        @include transition(color); }

    img {
        max-width: 100%;
        width: 100%;

        &[data-src],
        &[data-srcset] {
            display: block;
            min-height: 1px; } }

    svg {
        max-width: 100%; }

    p {
        margin-bottom: 2rem;

        @media all and (-ms-high-contrast:none) {
            max-width: 100%; }

        &:last-child {
            margin-bottom: 0; }

        span {
            display: block; } }
    h1 {
        text-align: center;
        @include fluid-type(360px, 1920px, 30px, 40px);
        line-height: 133.333%;
        font-family: $light;
        font-weight: normal;
        margin-bottom: 4rem;
        color: $tertiary;
        margin-top: 30%;
        span {
            display: block; }

        @media only screen and (device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3) {
            margin-top: 62%; }

        @media only screen and (device-width : 414px) and (device-height : 896px) {
            margin-top: 62%; }

        @media only screen and (min-width: 376px) {
            margin-top: 42%; }

        @media #{$tablet} {
            margin-top: 38rem; }

        @media #{$desktop} {
            margin-top: 44rem; }

        @media #{$desktopMid} {
            margin-top: 17rem; }

        @media #{$desktopBig} {
            margin-top: 26rem; }

        strong {
            display: block;
            color: $grey; } }

    h2 {
        @include fluid-type(360px, 1440px, 18px, 25px);
        line-height: 167%;
        font-weight: normal;
        color: $secondary;
        font-family: $bold;
        text-transform: uppercase;
        letter-spacing: 0.055em;
        text-align: center;
        margin-bottom: 1rem; }

    h3 {
        @include fluid-type(360px, 1440px, 28px, 70px);
        line-height: 118%;
        font-weight: normal;
        color: $inverse;
        font-family: $bold;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 4rem;

        &.mb0 {
            margin-bottom: 0; } }

    h4 {
        @include fluid-type(360px, 1440px, 24px, 35px);
        line-height: 125%;
        color: $secondary;
        font-weight: normal;
        font-family: $bold;
        letter-spacing: 0.03em;
        text-align: center;
        margin-bottom: 1.2rem;
        @media #{$tablet} {
            text-align: left; } }

    h5 {
        font-size: 1.9rem;
        line-height: 140%;
        color: $inverse;
        font-weight: normal;
        font-family: $bold;
        margin-bottom: 1.5rem;

        span {
            font-size: 1.6rem;
            display: block; } }

    .tns-transparent {
        opacity: 1;
        visibility: visible; }

    .noPadding {
        padding: 0; }

    .noPaddingSide {
        padding: 6rem 0;

        @media #{$tablet} {
            padding: 13rem 0; } }

    .noPaddingTop {
        padding: 0 2.4rem 6rem;

        @media #{$tablet} {
            padding: 0 3rem 13rem; } }

    .noPaddingBottom {
        padding: 6rem 2.4rem 0;

        @media #{$tablet} {
            padding: 13rem 3rem 0; } }

    .noPaddingTopBottom {
        padding: 0 2.4rem;

        @media #{$tablet} {
            padding: 0 3rem; } }

    .smallPaddingTop {
        padding: 3rem 2.4rem 6rem;

        @media #{$tablet} {
            padding: 13rem 3rem; } }

    .smallPaddingBottom {
        padding: 6rem 2.4rem;

        @media #{$tablet} {
            padding: 13rem 3rem 14rem; } }

    .center {
        text-align: center;
        margin-left: auto;
        margin-right: auto;

        @media #{$tablet} {
            text-align: left; } }

    .maxWidthMid {
        @media #{$desktop} {
            max-width: 58.8rem;
            margin-left: auto;
            margin-right: auto; } }

    .maxWidthFull {
        @media all and (-ms-high-contrast:none) {
            width: 100%; }
        @media #{$desktop} {
            max-width: 90.2rem;
            margin-left: auto;
            margin-right: auto; }

        &.video {
            max-width: 90.2rem;
            margin-left: auto;
            margin-right: auto; } } }

.logo {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    width: 100vw;
    height: 100vh;
    background: url('/assets/image/moving/bg_logo_3-4@2x.png') center center no-repeat;
    background-size: cover;
    z-index: 0 !important;

    @media #{$desktop} {
        background-image: url('/assets/image/moving/bg_logo_16-9@2x.png'); } }

.tns-lazy-img {
    opacity: 1 !important; }

#notice {
    position: fixed;
    top: 0;
    background: $inverse;
    width: 100%;
    max-width: 40rem;
    box-shadow: 0px 0px 7px rgba($black, 0.4);
    padding: 1.25rem  2.4rem;
    display: none;
    z-index: 31;

    &.show {
        display: block; }

    @media #{$tablet} {
        padding: 1.25rem 1rem; }

    @media #{$desktop} {
        left: 3rem;
        bottom: 0;
        top: auto; }

    p {
        width: 100%;
        max-width: 92rem;
        margin: 0 auto;
        color: $quintary;
        font-size: 1.2rem;
        line-height: 140%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media #{$desktop} {
            font-size: 1.5rem; }

        a {
            color: $quintary;
            display: inline-flex;
            margin: 1rem 0 0;
            text-decoration: underline;

            &.agree {
                margin-bottom: 0;
                text-decoration: none;
                background: $quintary;
                color: $inverse;
                padding: .3rem 1rem;
                line-height: 1;
                margin-left: 1.5rem; } } } }


