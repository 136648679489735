#sixtySeconds {
    color: $inverse;
    padding: 12rem 2rem 8.4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media all and (-ms-high-contrast:none) {
        padding-left: 0;
        padding-right: 0; }

    > * {
        z-index: 1; }

    @media #{$tablet} {
        padding-top: 20rem;
        min-height: 80rem; }

    video {
        width: 100%;
        height: 100%;
        z-index: 0;
        position: absolute;
        top: 4rem;
        left: 0;
        object-fit: cover;

        @media all and (-ms-high-contrast:none) {
            width: calc(100% + 8rem);
            margin-left: -2rem;
            margin-right: -2rem; }

        @media #{$desktop} {
            height: auto; }

        @media #{$desktopMid} {
            top: 0; } }

    .play {
        width: 7rem;
        height: 7rem;
        border-radius: 50%;
        background: url(/assets/image/layout/play.svg) center center no-repeat;
        background-size: 100% 100%;
        margin: 0 auto;
        cursor: pointer;
        z-index: 12;

        @media all and (-ms-high-contrast:none) {
            background: url(/assets/image/layout/play.png) center center no-repeat;
            position: absolute;
            top: 60%;
            left: 50%;
            transform: translateX(-50%); } } }
