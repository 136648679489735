#partnership {
    padding: 0;

    article {
        background-size: cover;
        background-position: bottom center;
        background-repeat: no-repeat;

        &.slider {
            background-size: cover;
            background-position: top center;
            padding-left: 0;
            padding-right: 0;

            @media #{$tablet} {
                height: auto;
                padding-bottom: 0; } }

        &.imgMax {
            padding-bottom: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            @media all and (-ms-high-contrast:none) {
                 overflow: hidden; }
            picture {
                padding: 0 3rem;
                img {
                    max-width: 90rem;
                    margin: 0 auto;

                    @media all and (-ms-high-contrast:none) {
                        margin-bottom: -2.4rem; } } } } } }
