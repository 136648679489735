#globalHeader {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    width: 100vw;
    z-index: -1;
    background: $quartery;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: .8rem 2rem;
    z-index: 30;
    transition: padding .3s cubic-bezier(0.4, 0, 0.2, 1), opacity .5s cubic-bezier(.4,0,.2,1), transform .5s cubic-bezier(.4,0,.2,1);

    @media #{$tablet} {
        padding: .8rem 3rem; }

    @media #{$desktop} {
         align-self: center;
         justify-content: center;
         padding: 3.6rem 0 1.8rem; }

    @media all and (-ms-high-contrast:none) {
        height: 12.6rem;
        nav {
            height: 5.6rem;
            ul.main {
                 height: 5.6rem;
                 top: 0; } } }

    &:after {
        content: '';
        width: 100%;
        width: 100vw;
        height: 100%;
        height: 100vh;
        max-width: 144rem;
        background: rgba($black, .6);
        position: fixed;
        top: 8rem;
        left: 0;
        opacity: 0;
        z-index: -1;
        transition: opacity .3s cubic-bezier(0.4, 0, 0.2, 1), transform .3s cubic-bezier(0.4, 0, 0.2, 1);
        transform: translateX(-100%);
        will-change: transform; }

    &.open {
        &:after {
            opacity: 1;
            transform: translateX(0); } }

    #logo {
        display: flex;
        align-items: center;
        justify-content: center;

        @media #{$desktop} {
            &.mobile {
                display: none; } }

        &.desktop {
            display: none;
            position: absolute;
            width: calc(100% - 6rem);
            left: 50%;
            top: 3rem;
            transform: translateX(-50%);
            height: 2.5rem;
            align-items: center;
            justify-content: space-between;
            transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1), transform 0.2s cubic-bezier(0.4, 0, 0.2, 1), margin 0.2s cubic-bezier(0.4, 0, 0.2, 1);
            pointer-events: auto;
            will-change: transform;
            margin-top: .2rem;

            @media all and (-ms-high-contrast:none) {
                top: 4.9rem; }

            &:after {
                content: '';
                width: calc(100% - 9.828rem);
                height: .354rem;
                background: $inverse;
                position: absolute;
                left: 6.475rem;
                bottom: 1rem; }

            svg {
                height: 2.5rem;
                width: auto;

                @media all and (-ms-high-contrast:none) {
                    width: 11.6rem;
                    &.right {
                        margin-left: auto; } } }

            @media #{$desktop} {
                display: flex;
                opacity: 1; } }

        svg {
            width: 8.38rem;
            height: 1.365rem; } }

    &.scroll {
        padding-top: .8rem;
        position: fixed;
        z-index: 900;

        @media all and (-ms-high-contrast:none) {
            height: 6rem; }

        .language {
            display: none; }

        &.langOpen {
            .language {
                padding: 0;
                .currentLang {
                    display: none; }
                .languageSwitch {
                    height: 0;
                    margin-top: 0;
                    li {
                        a {
                            font-size: 0; } } } }
            #logo {
                &.destkop {
                    margin-top: 0; } } }

        &:after {
            top: 4rem; }

        @media #{$desktop} {
            padding-top: 1.6rem; }

        #logo {
            &.desktop {
                transform: translateY(-100%) translateX(-50%);
                opacity: 0;
                pointer-events: none; } }

        .language {
            transform: translateY(-100%);

            @media #{$desktop} {
                opacity: 0;
                pointer-events: none;
                margin: 0;
                transform: translateY(-100%); } } }

    &.langOpen {
        @media all and (-ms-high-contrast:none) {
            height: 17.2rem; }
        #logo {
            &.desktop {
                margin-top: 2.4rem;

                @media all and (-ms-high-contrast:none) {
                    margin-top: 5.4rem; } } } } }
