#covid19-notice {

    position: absolute;
    background: $red;
    width: 100%;
    max-width: 440px;
    left: 0;
    bottom: 0;
    padding: 2.5rem 2.5rem 1rem 2.5rem;
    z-index: 500;

    @media #{$desktop} {
        bottom: 100px; }

    .close {
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
        content: "";
        width: 20px;
        height: 20px;
        background: url(/assets/image/layout/close.svg) bottom center no-repeat;
        color: #fff;
        font-weight: 300;
        font-family: Arial, sans-serif; }

    .headline {
        @include fluid-type(360px, 1440px, 16px, 24px);
        line-height: 125%;
        color: $inverse;
        font-weight: normal;
        font-family: $bold;
        letter-spacing: 0.03em;
        text-align: center;
        margin-bottom: 1.2rem;
        text-transform: uppercase; }

    p {
        margin-top: 1rem; }

    .button {
        position: relative !important;
        transform: none !important;
        left: auto !important;
        background-color: $inverse;
        color: $red;
        @include fluid-type(360px, 1440px, 16px, 24px);
        line-height: 1;
        padding: 1.3rem 3rem 1.1rem;
        text-align: center;
        border: 2px solid transparent;
        @include transition(color background border-color);
        display: inline-block;
        margin-top: 2rem;
        margin-bottom: 3rem;
        text-transform: uppercase;

        &:before {
            display: none; }

        &:hover {
            text-decoration: none;
            background: $red;
            color: $inverse;
            border-color: $inverse; } } }

#covid19 {

    width: 100%;
    width: 100vw;

    code {
        text-align: center;
        display: block;
        font-size: 1.5rem;
        font-weight: bold;
        font-family: TradeGothicLTPro-Light, Arial, sans-serif; }

    .back-to-top {
        position: fixed;
        bottom: 25px;
        right: 20px;
        padding: 1em;
        z-index: 100;

        @media #{$tablet} {
            bottom: 45px; }

        span {
            &:before {
                display: inline-block;
                background: url(/assets/image/layout/top.svg) bottom center no-repeat;
                width: 18px;
                height: 18px;
                content: "";
                margin: 0 0 -1px 0; } } }

    .toc {
        ul {
            list-style: none;
            li {
                list-style-position: inside;
                text-indent: -1.5rem;
                font-weight: 600;
                padding: 1rem 0 1rem 1.5rem;
                &:before {
                    color: $secondary;
                    font-size: 1.25rem;
                    display: inline-block;
                    background: url('/assets/image/layout/icon-arrow-right.svg') center center no-repeat;
                    width: 14px;
                    height: 14px;
                    content: " ";
                    margin: 4px 3px 0 0px;
                    bottom: 0;
                    vertical-align: text-top; } } } }

    a[target=_blank] {
        color: $secondary;
        position: relative;
        &:before {
            display: inline-block;
            content: "";
            background: url('/assets/image/layout/icon-external-link.svg') center center no-repeat;
            width: 15px;
            height: 15px;
            margin-right: 5px;
            margin-left: 5px;
            bottom: 0; } }

    a[title='Zum Antrag'], .button {
        background-color: $secondary;
        color: $primary;
        font-size: 2.2rem;
        line-height: 1;
        padding: 1.3rem 3rem 1.1rem;
        text-align: center;
        border: 2px solid transparent;
        @include transition(color background border-color);
        display: inline-block;
        margin-top: 2rem;
        margin-bottom: 3rem;
        text-transform: uppercase;

        &:before {
            display: none; }

        &:hover {
            text-decoration: none;
            background: $inverse;
            color: $primary;
            border-color: $inverse; } }

    ul, ol {
        padding: 0 0 0 20px;
        margin-bottom: 2rem; }


    h1 {
        @include fluid-type(360px, 1440px, 24px, 48px);
        line-height: 118%;
        font-weight: normal;
        color: $inverse;
        font-family: $bold;
        text-transform: uppercase;
        text-align: center;
        margin-top: 7rem;
        padding-top: 7rem;

        &.toc {
            border: none;
            margin-top: 0rem;
            padding-top: 0rem; }

        border-top: 1px solid #fff; }

    h2 {
        @include fluid-type(360px, 1440px, 22px, 30px);
        line-height: 125%;
        color: $inverse;
        font-weight: normal;
        font-family: $bold;
        letter-spacing: 0.03em;
        text-align: center;
        margin-bottom: 1.5rem;
        margin-top: 4rem;
        text-transform: none;
        @media #{$tablet} {
            text-align: left; } }

    article {
        padding: 6rem 0;

        &+ article {
            padding-top: 0; } }

    .list {
        list-style: none;
        margin-bottom: 2rem;

        li {
            padding-left: 2.5rem;

            &:before {
                content: '';
                width: 1.8rem;
                height: 1rem;
                background: url(/assets/image/layout/bulletWhite.svg) center center no-repeat;
                position: absolute;
                top: .7rem;
                left: 0; } } }

    h4 {
        color: $inverse;

        @media #{$tablet} {
            margin-bottom: 3rem; } }

    p {
        margin-left: auto;
        margin-right: auto;

        a {
            font-family: $bold2;
            font-weight: 400;

            &:hover {
                text-decoration: underline; } }

        strong {
            color: $inverse; } } }

#covid19Header {
    background: $quartery;
    width: 100%;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    padding: 1.25rem  .7rem;
    z-index: 8;
    min-height: 4rem;

    @media #{$tablet} {
        padding: 1.25rem 3rem; }

    .wrapper {
        width: 100%;
        height: 100%;
        max-width: 90.2rem;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .back {
            color: $inverse;
            font-size: 1.5rem;
            line-height: 1;
            padding-left: 2rem;
            z-index: 93475348957;
            position: absolute;
            top: 0;
            left: 0;

            &:hover {
                text-decoration: underline; }

            &:before {
                content: '';
                width: 1rem;
                height: .5rem;
                background: url('/assets/image/layout/arrowDown.svg') center center no-repeat;
                background-size: cover;
                position: absolute;
                top: 50%;
                transform: translateY(-50%) rotate(90deg);
                left: 0; } }

        .language {
            width: auto;
            align-items: flex-end;
            margin: 0;
            position: absolute;
            right: -.7rem;
            width: 100vw;
            padding-right: .7rem;
            top: -1.3rem;

            &.open {
                padding: 0 .7rem 0 0;
                top: 0;

                @media #{$tablet} {
                    padding-right: 3rem; } }

            .currentLang {
                margin: 0; }

            @media #{$tablet} {
                right: -3rem;
                padding-right: 3rem; }

            @media #{$desktop} {
                top: unset;
                right: unset;
                position: relative;
                padding: inherit;
                width: auto;
                min-width: 58rem;

                &.open {
                    padding: 0; } } } } }
