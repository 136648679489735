.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: unset !important;
    max-width: 90.2rem;

    &.mb {
        margin-bottom: 6rem; }

    @media #{$tablet} {
        margin: 0 -#{$gap / 2}rem 3rem; }

    @media #{$desktop} {
        margin: 0 auto; }

    .col {
        flex-basis: 100%;

        &.centered {
            @media #{$tablet} {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center; } }

        &.image {
            margin-bottom: 6rem;

            &:after {
                content: '';
                width: 100vw;
                height: 60%;
                background: $quartery;
                position: absolute;
                left: 50%;
                bottom: 0;
                transform: translateX(-50%);

                @media #{$tablet} {
                    content: none; } }

            picture {
                display: flex;
                align-items: center;
                z-index: 1;

                img {
                    margin: 3rem auto 0;
                    max-width: 73%; } } }

        &.flex {
            display: flex;
            flex-direction: row;
            margin-bottom: 2rem;

            @media #{$tablet} {
                align-items: flex-end;
                flex-direction: column;
                margin: 0;
                justify-content: space-between; }

            &:last-of-type {
                margin-bottom: 0; }

            .num {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 4.8rem;
                height: 4.8rem;
                border-radius: 50%;
                background: url('/assets/image/layout/icons/background.svg') center center no-repeat;
                background-size: cover;
                color: $secondary;
                line-height: 0;
                font-size: 2.1rem;
                font-family: $bold;
                padding-top: .3rem;
                margin-right: 1.5rem;

                @media #{$tablet} {
                    margin: 0 auto 2rem 0;
                    align-self: flex-start; } }

            p {
                max-width: calc(100vw - 4.8rem - 1.5rem - 4.8rem);
                display: inline-block;
                text-align: left;
                margin: 1.2rem 0 0;

                @media #{$tablet} {
                    max-width: 26.4rem;
                    margin: 0 0 auto 0; } } }

        @media #{$tablet} {
            flex: 1;
            padding: 0 #{$gap / 2}rem;

            &._5 {
                flex: 5; } }

        .icon {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            width: 100%;
            height: auto;
            display: block;
            margin: 0 auto 3rem;

            @media all and (-ms-high-contrast:none) {
                svg {
                    min-height: 25rem;
                    max-height: 25rem; } }

            @media #{$tablet} {
                margin-bottom: 0; } } }

    &.reverse {
        .col {
            &:first-child {
                order: 2;

                @media #{$tablet} {
                    order: 1; } }

            &:last-child {
                order: 1;

                @media #{$tablet} {
                    order: 2; } } } }


    &.small {
        margin: 0 -#{$gap / 2}rem;

        .col {
            flex: 1;
            padding: 0 #{$gap / 2}rem; } }

    &.footer {
        @media #{$desktop} {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin: 0;
            .col {
                flex: initial;
                padding: 0; } } } }

