/**
 * Transition Mixin
 */

@mixin transition($properties: all, $duration: .2s, $method: cubic-bezier(.4,0,.2,1), $delay: 0ms) {
    $value: ();
    @each $property in $properties {
        @if length($value) > 0 {
            $value: append($value, unquote(",") #{$property} $duration $method $delay); }
        @else {
            $value: append($value, #{$property} $duration $method $delay); } }
    transition: $value; }

/**
 * Fluid Typo mixin
 */

@function strip-unit($value) {
    @return $value / ($value * 0 + 1); }

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-font-size);
    $u4: unit($max-font-size);

    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
        & {
            font-size: $min-font-size;
            @media screen and (min-width: $min-vw) {
                font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})); }
            @media screen and (min-width: $max-vw) {
                font-size: $max-font-size; } } } }
