.video {
    width: 100%;
    margin: 3rem -2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $tertiary;

    // for dev
    background: url(/assets/image/moving/anim_LKWs.gif) center center no-repeat;
    background-size: contain;

    @media #{$tablet} {
        margin: 8rem -3rem; }

    > * {
        width: 100%;
        text-align: center; }

    &:after {
        content: '';
        width: 100%;
        padding-bottom: 56.25%;
        display: block; } }
