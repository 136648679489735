#legal,
#privacy {
    width: 100%;
    width: 100vw;
    article {
        padding: 6rem 0;

        &+ article {
            padding-top: 0; } }

    .list {
        list-style: none;
        margin-bottom: 2rem;

        li {
            padding-left: 2.5rem;

            &:before {
                content: '';
                width: 1.8rem;
                height: 1rem;
                background: url(/assets/image/layout/bulletWhite.svg) center center no-repeat;
                position: absolute;
                top: .7rem;
                left: 0; } } }

    h4 {
        color: $inverse;

        @media #{$tablet} {
            margin-bottom: 3rem; } }

    p {
        max-width: 90.2rem;
        margin-left: auto;
        margin-right: auto;

        a {
            font-family: $bold2;
            font-weight: 400;

            &:hover {
                text-decoration: underline; } }

        strong {
            color: $inverse; } } }
#legalHeader {
    background: $quartery;
    width: 100%;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    padding: 1.25rem  .7rem;
    z-index: 8;
    min-height: 4rem;

    @media #{$tablet} {
        padding: 1.25rem 3rem; }

    .wrapper {
        width: 100%;
        height: 100%;
        max-width: 90.2rem;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .back {
            color: $inverse;
            font-size: 1.5rem;
            line-height: 1;
            padding-left: 2rem;
            z-index: 93475348957;
            position: absolute;
            top: 0;
            left: 0;

            &:hover {
                text-decoration: underline; }

            &:before {
                content: '';
                width: 1rem;
                height: .5rem;
                background: url('/assets/image/layout/arrowDown.svg') center center no-repeat;
                background-size: cover;
                position: absolute;
                top: 50%;
                transform: translateY(-50%) rotate(90deg);
                left: 0; } }

        .language {
            width: auto;
            align-items: flex-end;
            margin: 0;
            position: absolute;
            right: -.7rem;
            width: 100vw;
            padding-right: .7rem;
            top: -1.3rem;

            &.open {
                padding: 0 .7rem 0 0;
                top: 0;

                @media #{$tablet} {
                    padding-right: 3rem; } }

            .currentLang {
                margin: 0; }

            @media #{$tablet} {
                right: -3rem;
                padding-right: 3rem; }

            @media #{$desktop} {
                top: unset;
                right: unset;
                position: relative;
                padding: inherit;
                width: auto;
                min-width: 58rem;

                &.open {
                    padding: 0; } } } } }
