@import url("//hello.myfonts.net/count/3825a0");


@font-face {font-family: 'TradeGothicLTPro-Light';src: url('/assets/fonts/3825A0_0_0.eot');src: url('/assets/fonts/3825A0_0_0.eot?#iefix') format('embedded-opentype'),url('/assets/fonts/3825A0_0_0.woff2') format('woff2'),url('/assets/fonts/3825A0_0_0.woff') format('woff'),url('/assets/fonts/3825A0_0_0.ttf') format('truetype'),url('/assets/fonts/3825A0_0_0.svg#wf') format('svg');}


@font-face {font-family: 'TradeGothicLTPro-Bold';src: url('/assets/fonts/3825A0_1_0.eot');src: url('/assets/fonts/3825A0_1_0.eot?#iefix') format('embedded-opentype'),url('/assets/fonts/3825A0_1_0.woff2') format('woff2'),url('/assets/fonts/3825A0_1_0.woff') format('woff'),url('/assets/fonts/3825A0_1_0.ttf') format('truetype'),url('/assets/fonts/3825A0_1_0.svg#wf') format('svg');}


@font-face {font-family: 'TradeGothicLTPro-Bd2';src: url('/assets/fonts/3825A0_2_0.eot');src: url('/assets/fonts/3825A0_2_0.eot?#iefix') format('embedded-opentype'),url('/assets/fonts/3825A0_2_0.woff2') format('woff2'),url('/assets/fonts/3825A0_2_0.woff') format('woff'),url('/assets/fonts/3825A0_2_0.ttf') format('truetype'),url('/assets/fonts/3825A0_2_0.svg#wf') format('svg');}
