.slider {
    .slide {
        list-style: none;

        li {
            padding: 0 2.4rem 5rem 2.4rem;

            @media #{$tablet} {
                padding: 0 3rem; }

            .container {
                display: flex;
                width: 100%;
                flex-flow: row wrap;
                justify-content: space-between;
                align-items: flex-end;
                max-width: 90.2rem;
                margin-left: auto;
                margin-right: auto;

                @media #{$tablet} {
                    align-items: flex-start; } } }

        h5 {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 50%;
            order: 2;

            @media #{$tablet} {
                margin-top: 8rem;
                max-width: 36rem; } }

        picture {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 50%;
            order: 1;

            img {
                width: auto;
                height: 210px;
                @media #{$tablet} {
                    height: 440px;
                    width: auto;
                    margin-right: auto; }
                @media #{$desktop} {
                    height: 550px;
                    width: auto; } } }

        .quote {
            @include fluid-type(360px, 1440px, 18px, 25px);
            width: 100%;
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 100%;
            order: 3;
            line-height: 120%;
            background: #85959D;
            padding: 1rem 1.5rem;

            @media #{$tablet} {
                max-width: 36rem;
                position: absolute;
                top: 25%;
                right: 0;
                margin-top: 6rem;
                background: transparent;
                padding: 0; } } }

    .tns-nav {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        max-width: 90.2rem;
        bottom: 1rem;
        margin: 0 -.3rem;
        z-index: 20;
        padding: 0 2.4rem;

        @media #{$tablet} {
            margin: 0 0 11.3rem;
            bottom: -.5rem;
            display: flex;
            justify-content: flex-end;
            padding: 0 3rem; }

        button {
            width: 1.5rem;
            height: 1.5rem;
            border: .2rem solid $inverse;
            background: transparent;
            border-radius: 50%;
            margin: 0 .5rem;

            &.tns-nav-active {
                background: $inverse; } } }

    .sliderControls {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 107rem;
        height: 10rem;
        appearance: none;
        -webkit-appearance: none;
        outline: none;

        &:focus {
            outline: none;
            appearance: none {
             -webkit-appearance: none; } }

        span {
            display: block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            transform-origin: center center;
            width: 5rem;
            height: 6.5rem;
            background: url('/assets/image/layout/sliderArrow.svg') center center no-repeat;
            background-size: contain;
            font-size: 0;
            @include transition(transform, .5s);
            cursor: pointer;
            appearance: none;
            -webkit-appearance: none;
            outline: none;

            &:focus {
                outline: none;
                appearance: none;
                -webkit-appearance: none; }

            &.prev {
                left: 0;
                transform: rotate(-180deg) translateY(-50%);
                transform-origin: top; }

            &.next {
                right: 0; } } } }
