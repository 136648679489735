.language {
    padding: 1.25rem 0;
    background: $quartery;
    width: 100%;
    z-index: 100;
    opacity: 1;
    @include transition(all, 0.2s);
    transform: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-direction: column;
    max-height: 4rem;
    margin: 0;

    @media #{$tablet} {
        padding: 1.25rem 0;
        margin-bottom: .1rem; }

    @media #{$desktop} {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        visibility: visible;
        padding: 0; }

    .currentLang {
        color: $inverse;
        font-size: 1.5rem;
        line-height: 1;
        padding-right: 2rem;
        padding-left: 3rem;
        cursor: pointer;
        margin-right: auto;

        @media #{$desktop} {
            margin: 0 auto; }

        &:before {
            content: '';
            width: 2.2rem;
            height: 2.2rem;
            background: url('/assets/image/layout/world.svg') center center no-repeat;
            background-size: cover;
            position: absolute;
            top: -.4rem;
            left: 0; }

        &:after {
            content: '';
            width: 1rem;
            height: .5rem;
            background: url('/assets/image/layout/arrowDown.svg') center center no-repeat;
            background-size: cover;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            will-change: transform;
            transform-origin: top;
            @include transition(transform); } }

    &.hide {
        opacity: 0;
        pointer-events: none; }

    &.open {
        max-height: unset;
        justify-content: flex-start;

        @media #{$desktop} {
            padding: 0 3rem;
            max-height: 8rem; }

        .currentLang {
            padding-bottom: 2rem;
            &:after {
                top: .9rem;
                transform: rotate(180deg) translateY(0); } }
        .languageSwitch {
            opacity: 1;
            pointer-events: auto;
            height: auto;

            @media all and (-ms-high-contrast:none) {
                z-index: auto; }

            li {
                a {
                    font-size: 1.8rem; } } } }

    .languageSwitch {
        opacity: 0;
        pointer-events: none;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        list-style: none;
        display: flex;
        height: 0;
        // +transition(all, 0.2s)

        @media all and (-ms-high-contrast:none) {
            z-index: -12; }

        li {
            a {
                color: $secondary;
                padding: .75rem;
                font-family: $bold;
                text-transform: uppercase;
                @include transition(color, .2s); }

            &.active,
            &:hover {
                a {
                    color: $inverse; } } } } }
