#firstscreen {
    width: 100%;
    min-height: 100vh;
    width: 100vw;
    display: flex;
    pointer-events: auto;
    background: $inverse;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    opacity: 1;
    transition: opacity .4s cubic-bezier(0.4, 0, 0.2, 1), transform .4s cubic-bezier(0.4, 0, 0.2, 1);
    overflow: hidden;
    margin-bottom: 0.1rem;

    @media all and (-ms-high-contrast:none) {
        height: 100vh; }

    @media #{$desktop} {
        margin-bottom: 0.1rem;
        overflow: visible; }

    &.fadeOut {
        opacity: 0;
        transform: translateY(-10rem); }

    > * {
        flex: 1;
        position: relative;
        z-index: 2; }

    .legalNav {
        position: absolute;
        bottom: 13%;
        left: 50%;
        transform: translateX(-50%);
        list-style: none;
        width: 100%;
        max-width: 144rem;
        padding: 0;
        display: flex;
        justify-content: space-between;

        @media #{$tablet} {
            bottom: 6rem;
            padding: 0 3rem;
            justify-content: flex-end; }

        @media #{$desktop} {
            bottom: 3rem; }

        li {
            padding: 0 1rem;

            a {
                color: $quintary;
                font-size: 1.2rem;
                line-height: 1;
                &:hover {
                    text-decoration: underline; } } } }

    .language {
        flex: inherit;
        padding: 1.25rem 2rem;

        @media #{$tablet} {
            padding: 1.25rem 3rem; }

        @media #{$desktop} {
            padding: 1.25rem 0; } }

    .marquee {
        padding: 1.2rem 0;
        list-style: none;
        background: $secondary;
        overflow: hidden;
        width: 100%;
        max-height: 4rem;
        min-height: 4rem;
        margin: 0;

        @media #{$tablet} {
            margin-top: -.1rem; }

        > * {
            color: $primary;
            font-size: 1.6rem;
            line-height: 1; }

        li {
            padding: 0 .75rem 0 3.25rem;
            font-family: $bold;
            display: inline-block;

            &:before {
                content: '';
                width: 1.907rem;
                height: 1.6rem;
                background: url('/assets/image/layout/icons/checkmarker.svg') center center no-repeat;
                background-size: cover;
                position: absolute;
                top: 50%;
                left: 0.75rem;
                transform: translateY(-50%); } } }

    header {
        width: 100%;
        width: 100vw;
        padding: 1.6rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        max-height: 5.6rem;
        max-width: 144rem;
        margin: 0 auto;

        @media #{$desktop} {
            padding: 3rem;
            max-height: 8.4rem; }

        a {
            font-size: 1.8rem;
            line-height: 1;
            color: $tertiary;
            padding-left: 3rem;
            font-family: $bold;

            &.logo-rhenus {
                padding-left: 0; }

            @media #{$desktop} {
                font-size: 2.7rem;
                padding-left: 4.5rem; }

            &:before {
                content: '';
                width: 2rem;
                height: 2rem;
                background: url('/assets/image/layout/icons/phone.svg') center center no-repeat;
                background-size: cover;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);

                @media #{$desktop} {
                    width: 2.9rem;
                    height: 2.9rem; } }

            &.logo-rhenus {
                &:before {
                    display: none; } } }

        img {
            width: 100%;
            max-width: 12.7rem;
            height: 2.4rem;

            @media #{$desktop} {
                max-width: 19.5rem;
                height: 3rem; }

            use {
                fill: $primary; } } }

    article {
        padding: 6rem 0 4rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        .button {
            margin-bottom: 4rem; }

        @media #{$desktop} {
            justify-content: space-between;

            h1 {
                position: absolute;
                left: 50%;
                bottom: 50%;
                transform: translateX(-50%);
                margin: 0 !important; }

            .button {
                z-index: 20;
                position: absolute;
                left: 50%;
                bottom: 30%;
                transform: translateX(-50%);
                margin-bottom: 0; }

            .toggleScroll {
                position: absolute;
                left: 50%;
                bottom: 15%;
                transform: translateX(-50%); } }

        @media #{$desktopMid} {
            .button {
                margin-bottom: 2.2rem;

                &:after {
                    height: 15rem; } } } } }
